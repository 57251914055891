import {useRecoilState} from 'recoil'
import {defaultActiveOrdersFilter} from '../../../../../../../../utils/states/activeFilter.state'
import React, {useEffect, useState} from 'react'
import {useGetOrdersQuery, useUpdateOrderStatusMutation} from '../../../../../../../../redux/services/OrdersApi'
import mapOrders from '../../../../../../../../utils/functions/map/mapOrders'
import {Selector} from '../../../../../components/Selector/Selector'
import Income from '../../../../../components/Income/Income'
import {Pagination} from '../../../../../../../../components/Pagination/Pagination'
import {Blocks} from '../../../../../../../../components/Blocks/Blocks'
import {useGetDisputeByIdQuery} from "../../../../../../../../redux/services/DisputesApi";
import {Loading} from "../../../../../../../../components/Loading/Loading";
import {ModalDefault} from "../../../../../../../../components/ModalDefault/ModalDefault";
import {PureModal} from "../../PureModal";
import styles from "../../../../../components/Filter/Filter.module.css";

export const OrderBlocks = () => {
  const [{activeOrdersFilter}] = useRecoilState(defaultActiveOrdersFilter)
  const [page, setPage] = useState<number>(1)
  const [pageSize,] = useState<number>(7)
  const [openSelector, setOpenSelector] = useState<string | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [orderUid, setOrderUid] = useState<string>('')
  const [searchId, setSearchId] = useState<string>()
  const {data} = useGetOrdersQuery(
    {status: activeOrdersFilter, page: page, page_size: pageSize, id: searchId?.trim() || ''},
    {pollingInterval: 5000})
  const {data: disputes, isLoading: isDisputeLoading} = useGetDisputeByIdQuery(orderUid, {
    skip: orderUid === ''
  })
  const orders = mapOrders(data?.results) || []
  const [updateOrder] = useUpdateOrderStatusMutation()
  const handleEdit = async (newStatus: string, id: string) => {
    const body = {
      status: newStatus,
    }
    await updateOrder({body, id})
      .unwrap()

    /** Временный hotfix.
     * Проблема в том что если мы находимся на 2й странице, у нас стоит фильтр по статусу и там только 1 айтем, то после смены статуса заявки,
     * летит запрос на бэк за обновлением данных таблицы с page=2, но т.к. мы сменили статус то у нас теперь только одна страница, back возвращает error Invalid page.
     * TODO: нужно более системное и надежное решение.
     * */
    if (activeOrdersFilter && data?.results.length === 1 && page > 1) {
      setPage(page - 1);
    }
  }

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    setPage(1)
  }, [activeOrdersFilter])
  if (isDisputeLoading) return <Loading/>
  return (
    <>
      <div style={{width: '100%', display: 'flex', maxWidth: '440px'}}>
         <span style={{
           width: '70%',
           display: 'flex',
           marginBottom: '12px',
           alignItems: 'center'
         }}>
            Найдите заявку:
         </span>
        <input
          className={styles.input}
          type={'text'}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
      </div>
      <ModalDefault
        title={'Чек по операции'}
        active={openModal}
        setActive={setOpenModal}
        children={<PureModal orderUid={orderUid}/>}
      />
      <Blocks
        blocks={
          orders?.map((order) => {
            return (
              <div
                className={'block'}
                key={order.id}
                style={order.statusId === 'BN' ? {background: '#F3FFF4'} : order.statusId === 'ID'
                  ? {background: 'rgba(246,86,86,0.41)'}
                  : {}}
              >
                <div className={'component'}>
                  <p className={'block-component'}>ID:</p>
                  <p className={'block-value'}>
                    {order.statusId === 'ID'
                      ? <>
                        <a style={{cursor: 'pointer', color: '#1890FF', textDecorationLine: 'underline'}}
                           onClick={() => {
                             setOpenModal((prevState) => !prevState)
                             setOrderUid(order.id)
                           }}>
                          {order.id}
                        </a>
                      </>
                      : order.id}
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Статус:</p>
                  <p className={'block-value'}>
                    <Selector
                      open={openSelector}
                      setOpen={setOpenSelector}
                      id={order.id}
                      status={order.status}
                      statusId={order.statusId}
                      handleEdit={handleEdit}
                      needRecalculation={true}/>
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Реквизиты:</p>
                  <p className={'block-value'}>
                    <div>
                      <div>{order.fio}</div>
                      {order.card_number}</div>
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Банк:</p>
                  <p className={'block-value'}>{order.bank}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Ожидается:</p>
                  <p className={'block-value'}>{order.expected_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Пришло:</p>
                  <p className={'block-value'}>
                    {order.statusId === 'ID' ? <>{order.temp_income}<Income order={order}/></> :
                      <Income order={order}/>}
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Дата:</p>
                  <p className={'block-value'}>{order.date}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Срок действия:</p>
                  <p className={'block-value'}>{order.time_left ? formatTime(order.time_left) : ''}</p>
                </div>
              </div>
            )
          }) || []
        }
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  )
}
