import { useRecoilState } from 'recoil'
import { defaultActiveWithdrawalsFilter } from '../../../../../../../../utils/states/activeFilter.state'
import React, { useEffect, useState } from 'react'
import {
  useGetWithdrawalsQuery,
  useUpdateWithdrawalStatusMutation
} from '../../../../../../../../redux/services/WithdrawalsApi'
import calculateElements from '../../../../../../../../utils/functions/calcucateElements'
import { Blocks } from '../../../../../../../../components/Blocks/Blocks'
import { Pagination } from '../../../../../../../../components/Pagination/Pagination'
import { mapWithdrawals } from '../../../../../../../../utils/functions/map/mapWithdrawals'
import styles from "../../../../../components/Filter/Filter.module.css";
import {ModalDefault} from "../../../../../../../../components/ModalDefault/ModalDefault";
import {PureModal} from "../../../../../Orders/components/Content/PureModal";
import {Selector} from "../SelectorWithdrawls/Selector";

const WithdrawalsBlocks = () => {
  const [{ activeWithdrawalsFilter }] = useRecoilState(defaultActiveWithdrawalsFilter)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(7)
  const [openSelector, setOpenSelector] = useState<string | null>(null)
  const [searchId, setSearchId] = useState<string>()
  const [openModal, setOpenModal] = useState(false)
  const [withdrawalUid, setWithdrawalUid] = useState<string>('')

  const { data } = useGetWithdrawalsQuery(
    { status: activeWithdrawalsFilter, page: searchId ? 1 : page, page_size: pageSize, id: searchId?.trim() || '' },
    { pollingInterval: 10000 },
  )
  const [updateWithdrawal] = useUpdateWithdrawalStatusMutation()
  const handleEdit = async (newStatus: string, id : string) => {
    const body = {
      status: newStatus,
    }
    await updateWithdrawal({body,id})
    /** Временный hotfix.
     * Проблема в том что если мы находимся на 2й странице, у нас стоит фильтр по статусу и там только 1 айтем, то после смены статуса заявки,
     * летит запрос на бэк за обновлением данных таблицы с page=2, но т.к. мы сменили статус то у нас теперь только одна страница, back возвращает error Invalid page.
     * TODO: нужно более системное и надежное решение.
     * */
    if (activeWithdrawalsFilter && data?.results.length === 1 && page > 1) {
      setPage(page - 1);
    }
  }
  const handleResize = () => {
    setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7))
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setPage(1)
  }, [activeWithdrawalsFilter])
  return (
    <>
      <div style={{width: '100%', display: 'flex', maxWidth: '440px'}}>
            <span style={{
              width: '70%',
              display: 'flex',
              marginBottom: '12px',
              alignItems: 'center'
            }}>Найдите заявку:</span>
        <input className={styles.input} type={'text'} value={searchId} onChange={(e) => setSearchId(e.target.value)}/>
      </div>
      <ModalDefault
        title={'Чек по операции'}
        active={openModal}
        setActive={setOpenModal}
        children={<PureModal orderUid={withdrawalUid}/>}
      />
      <Blocks
        blocks={
          mapWithdrawals(data?.results).map((withdrawal) => {
            return (
              <div
                className={'block'}
                key={withdrawal.id}
                style={withdrawal.statusId === 'BN' ? { background: '#FFF5F5' } : {}}
              >
                <div className={'component'}>
                  <p className={'block-component'}>UID:</p>
                  <p className={'block-value'}>
                    {withdrawal.statusId === 'ID'
                      ? <>
                        <a style={{cursor: 'pointer', color: '#1890FF', textDecorationLine: 'underline'}}
                           onClick={() => {
                             setOpenModal((prevState) => !prevState)
                             setWithdrawalUid(withdrawal.id)
                           }}>
                          {withdrawal.id}
                        </a>
                      </>
                      : withdrawal.id}
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Статус:</p>
                  <p className={'block-value'}>
                    <Selector
                      open={openSelector}
                      setOpen={setOpenSelector}
                      id={withdrawal.id}
                      status={withdrawal.status}
                      statusId={withdrawal.statusId}
                      pageName={'withdraw'}
                      handleEdit={handleEdit} />
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Реквизиты:</p>
                  <p className={'block-value'}>
                    {withdrawal.last_name} {withdrawal.first_name} <br/> {withdrawal.card_number}
                  </p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Банк:</p>
                  <p className={'block-value'}>{withdrawal.bank_full_name || 'Другой банк'}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Примечание, доп.инфо:</p>
                  <p className={'block-value'}>{withdrawal.account_extra_info ?? '-'}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Ожидается:</p>
                  <p className={'block-value'}>{withdrawal.expected_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Дата:</p>
                  <p className={'block-value'}>{withdrawal.date}</p>
                </div>
              </div>
            )
          }) || []
        }
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </>
  )
}
export default WithdrawalsBlocks