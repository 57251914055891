import React from 'react'
import './ErrorMessagePage.css'
import {useNavigate} from 'react-router-dom'
import {AUTH} from "../../utils/constants/routes";

interface IErrorPageProps {
  errorCode: string
  errorText: string
}

export const ErrorMessagePage = (props: IErrorPageProps) => {
  const {errorCode, errorText} = props
  const navigate = useNavigate()
  return (
    <div className={'error_page'}>
      <div className={'error_page_wrapper'}>
        <div className={'error_icon'}>
          <img src={'/error-page-icon.png'} alt={'error page icon'}/>
        </div>
        <div className={'error_content'}>
          <h1 className={'error_code'}>{errorCode}</h1>
          <h3 className={'error_text'}>{errorText}</h3>
          <input
            className={'error_button'}
            type={'button'}
            value={'Назад'}
            onClick={() => navigate(AUTH)}
          />
        </div>
      </div>
    </div>
  )
}
