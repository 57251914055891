import {useRecoilState} from 'recoil'
import {defaultActiveWithdrawalsFilter} from 'utils/states/activeFilter.state'
import {CSSProperties, useEffect, useMemo, useState} from 'react'
import {
  useGetWithdrawalsQuery,
  useUpdateWithdrawalStatusMutation
} from 'redux/services/WithdrawalsApi'
import {Table} from 'components/Table/Table'
import {withdrawalsThArray} from 'utils/constants/th_arrays'
import {mapWithdrawals} from 'utils/functions/map/mapWithdrawals'
import {Pagination} from 'components/Pagination/Pagination'
import {pushNotify} from "components/Notifications";
import {ModalDefault} from "../../../../../../../../components/ModalDefault/ModalDefault";
import {PureModal} from "../../../../../Orders/components/Content/PureModal";
import {Selector} from "../SelectorWithdrawls/Selector";
import {ERROR, UploadImage} from "../../../../../../../../components/UploadImage";
import {Button} from "../../../../../../../../components/Button/Button";
import {mediaUrl} from "../../../../../../../../utils/constants/url";
import {getShortString} from "../../../../../../../../utils/functions/getShortString";
import styles from '../../../../../components/Filter/Filter.module.css';

const WithdrawalsTable = () => {
  const [{activeWithdrawalsFilter}] = useRecoilState(defaultActiveWithdrawalsFilter)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [idStatusEdit, setIdStatusEdit] = useState<string | null>()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [openSelector, setOpenSelector] = useState<string | null>(null)
  const [searchId, setSearchId] = useState<string>()
  const [error, setError] = useState<string>()
  const [prevCount, setPrevCount] = useState<number | null | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [withdrawalUid, setWithdrawalUid] = useState<string>('');
  const [files, setFiles] = useState<any[]>([null, null, null]);
  const [fileName, setFileName] = useState<string | null>(null);
  const {data} = useGetWithdrawalsQuery(
    {status: activeWithdrawalsFilter, page: searchId ? 1 : page, page_size: pageSize, id: searchId?.trim() || ''},
    {pollingInterval: 10000}
  )
  const {data: disputes} = useGetWithdrawalsQuery(
    {status: 'ID', page: undefined, page_size: undefined},
    {pollingInterval: 5000}
  );
  const [updateWithdrawal] = useUpdateWithdrawalStatusMutation()

  const handleEdit = async (newStatus: string, id: string) => {
    if (newStatus === 'SF') {
      setIdStatusEdit(id)
      setIsShowModal(true);
    } else {
      updateStatus(newStatus, id);
    }
  }

  const updateStatus = async (newStatus: string, id: string) => {
    const body = {
      status: newStatus,
    }
    await updateWithdrawal({body, id})

    /** Временный hotfix.
     * Проблема в том что если мы находимся на 2й странице, у нас стоит фильтр по статусу и там только 1 айтем, то после смены статуса заявки,
     * летит запрос на бэк за обновлением данных таблицы с page=2, но т.к. мы сменили статус то у нас теперь только одна страница, back возвращает error Invalid page.
     * TODO: нужно более системное и надежное решение.
     * */
    if (activeWithdrawalsFilter && data?.results.length === 1 && page > 1) {
      setPage(page - 1);
    }
  }

  const uploadFile = async (file: any, index: number) => {
    files[index] = file
    setFiles([...files]);
  }

  const setModalFile = (fileName: string) => {
    if (fileName.slice(-4) === '.pdf') {
      return window.open(
        mediaUrl + fileName,
        '_blank'
      )
    }
    setFileName(fileName);
    setOpenModal(true);
  }

  const uploadStatusSF = () => {
    if (!idStatusEdit) return
    setError( '');
    const thisStatus = data?.results.find(x => x.id === idStatusEdit)?.status
    if (thisStatus && !['ID', 'BN'].includes(thisStatus)) {
      setError( 'Статус заявки нельзя изменить');
      setDisabled(true);
      return;
    }
    const formData = new FormData()
    formData.append('status', 'SF')
    for (const file of files.filter(x => x)) {
      formData.append('files', file, file?.name)
    }
    setDisabled(true);
    // @ts-ignore
    updateWithdrawal({ body: formData, id: idStatusEdit })
      .then((response: any) => {
        if (!response.error) {
          setIsShowModal(false);
          setIdStatusEdit(null);
        }
        setError(response?.error?.data?.files?.[0]?.[0]);
      })
      .catch((error) => {
        setError(JSON.stringify(error));
      })
      .finally(() => setDisabled(false));
  }

  const handleResize = () => {
    // setPageSize(calculateElements([720, 760, 820, 870, 940, 990], 7))
  }

  const getStyle = (baseStyle: CSSProperties): CSSProperties => ({
    ...baseStyle,
    padding: baseStyle.padding || '0',
    wordBreak: 'normal' as CSSProperties['wordBreak'],
  })

  const getOrderStyle = (statusId: string): CSSProperties => {
    switch (statusId) {
      case 'BN':
        return {background: '#F3FFF4'};
      case 'ID':
        return {background: 'rgba(246, 86, 86, 0.41)'};
      case 'AAD':
        return {background: 'rgba(203, 202, 202, 041)'};
      default:
        return {}
    }
  };

  const linkStyle: CSSProperties = {cursor: 'pointer', color: 'blue', textDecoration: 'underline'};

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  const isHaveErrorFiles = useMemo(() => !!files.filter(x => x === ERROR)?.length, [files])

  useEffect(() => {
    if (disputes && prevCount === undefined) {
      setPrevCount(disputes.count);
    }
  }, [disputes, prevCount]);

  useEffect(() => {
    if (disputes && prevCount != null) {
      if (disputes.count > prevCount) {
        pushNotify(`НОВЫЙ СПОР`, 'ВНИМАНИЕ, НОВЫЙ СПОР');
        setPrevCount(disputes.count);
      }
      setPrevCount(disputes.count)
    }
  }, [disputes]);

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    setPage(1)
  }, [activeWithdrawalsFilter]);

  useEffect(() => {
    if (!isShowModal) {
      setFiles([]);
    }
  }, [isShowModal]);

  return (

    <div>
      <div style={{width: '100%', display: 'flex', maxWidth: '440px'}}>
        <span style={{
          width: '70%',
          display: 'flex',
          marginBottom: '12px',
          alignItems: 'center'
        }}>
          Найдите заявку:
        </span>
        <input
          className={styles.input}
          type={'text'}
          value={searchId}
          onChange={(e) => setSearchId(e.target.value)}
        />
      </div>
      <ModalDefault
        title="Чек по операции"
        active={openModal}
        setActive={setOpenModal}
        key="modalCheck"
      >
        {fileName
          ? <img src={mediaUrl + fileName} alt="" className={styles.checkImage} />
          : <PureModal orderUid={withdrawalUid}/>
        }
      </ModalDefault>
      <ModalDefault
        title="Загрузить чек"
        active={isShowModal}
        setActive={setIsShowModal}
        key="uploadCheck"
      >
        <UploadImage updateImage={(file: any) => uploadFile(file, 0)} />
        <UploadImage updateImage={(file: any) => uploadFile(file, 1)} />
        <UploadImage updateImage={(file: any) => uploadFile(file, 2)} />
        {error &&
          <div className={styles.error}>
            {error}
          </div>
        }
        <Button action={uploadStatusSF} text='Отправить' disabled={isHaveErrorFiles || disabled || !!error || !files?.filter(x => x)?.length} />
      </ModalDefault>
      <Table
        thArray={withdrawalsThArray}
        tdArray={mapWithdrawals(data?.results)?.map((withdrawal) => {
          const style = getOrderStyle(withdrawal.statusId);
          return [
            {
              element: withdrawal.statusId === 'ID' ? (
                <a
                  style={linkStyle}
                  onClick={() => {
                    setOpenModal(true);
                    setWithdrawalUid(withdrawal.id)
                  }}
                >
                  {withdrawal.id}
                </a>
              ) : withdrawal.id,
              style
            },
            {
              element: <Selector
                open={openSelector}
                setOpen={setOpenSelector}
                id={withdrawal.id}
                status={withdrawal.status}
                statusId={withdrawal.statusId}
                pageName={'withdraw'}
                handleEdit={handleEdit}/>,
              style: getStyle(style)
            },
            {element: <div>{withdrawal.last_name} {withdrawal.first_name} {withdrawal.card_number}</div>, style},
            {element: withdrawal.bank_full_name || 'Другой банк', style},
            {element: withdrawal.account_extra_info ?? '-', style},
            {element: withdrawal.expected_income, style},
            {
              element: withdrawal.date,
              style: getStyle({...style, padding: '10px'})
            },
            {element: withdrawal.time_left ? formatTime(withdrawal.time_left) : '', style},
            {element: withdrawal.files.map(file =>
              <div onClick={() => setModalFile(file.file_path)} className={styles.link}>
                { getShortString(file.file_name) }
              </div>
            ), style},
          ]
        }) || []}
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </div>
  )
}

export default WithdrawalsTable