import {useState} from 'react'
import {type SubmitHandler, useForm, FormProvider} from 'react-hook-form'
import {useAuthMutation, useUnlockMutation} from '../../../redux/services/AuthApi'
import {Loading} from '../../../components/Loading/Loading'
import {Button} from '../../../components/Button/Button'
import {ModalDefault} from '../../../components/ModalDefault/ModalDefault'
import {useNavigate} from 'react-router-dom'
import MainContainerWithWindow from '../../../components/MainContainerWithWindow/MainContainerWithWindow'
import {APPLICATIONS} from '../../../utils/constants/routes'
import {BaseInput} from '../../../components/BaseInput/BaseInput'
import {Auth2FA} from "../2fa/Auth2FA";
import styles from './Auth.module.css'

interface TInputs {
  username: string
  password: string
  otp_code: string
}

export const Auth = () => {
  const formMethods = useForm<TInputs>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const {
    handleSubmit,
  } = formMethods
  const navigate = useNavigate()
  const [authRequest, {isLoading}] = useAuthMutation()
  const [unlockRequest] = useUnlockMutation()
  const [is2FA, setIs2FA] = useState<boolean>(false)
  const [errors, setErrors] = useState<any | null>(null)
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [qrData, setQrData] = useState<any | null>(null)
  const handlePasswordVisible = () => {
    setPasswordVisible((prevState) => {
      return !prevState
    })
  }
  const onSubmit: SubmitHandler<TInputs> = async (data) => {
    await authRequest(data)
      .unwrap()
      .then((response) => {
        const token = response.auth_token
        localStorage.setItem('token', token)
        navigate(APPLICATIONS)
        window.location.reload()
      })
      .catch((error) => {
        unlockRequest(null);
        if (error?.data?.otp_code && !is2FA) {
          setErrors(null);
          setIs2FA(true);
          if (error?.data?.qr_data) {
            setQrData(error?.data?.qr_data);
          }
          return;
        }
        if (error?.data) {
          for (const key in error?.data) {
            setErrors({[key]: error?.data[key][0]})
          }
        }
      })
  }
  if (isLoading) {
    return <Loading/>
  }
  return (
    <FormProvider {...formMethods}>
      <MainContainerWithWindow>
        <div className={styles.wrapper}>
          <center>
            <img src={'/paygo.svg'} alt={'logo'} className={styles.logo}/>
          </center>
          <h1>{is2FA ? 'Двухфакторная аунтентификация' : 'Вход'}</h1>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            {is2FA
              ? <Auth2FA errors={errors} qrData={qrData}/>
              : (
                <div>
                  <BaseInput
                    title={''}
                    placeholder={'Логин'}
                    rules={{required: true}}
                    name={'username'}
                    type={'text'}
                    imgLeft={errors?.username || errors?.root ? '/user_icon_red.png' : '/user_icon.png'}
                    imgLeftWidth={24}
                  />
                  {errors?.username && <p>Введите имя пользователя</p>}
                  <BaseInput
                    imgLeft={
                      errors?.password || errors?.root ? '/password_icon_red.png' : '/password_icon.png'
                    }
                    imgLeftWidth={24}
                    imgRight={passwordVisible ? '/eye_icon_active.png' : '/eye_icon.png'}
                    imgRightAction={handlePasswordVisible}
                    title={''}
                    placeholder={'Пароль'}
                    rules={{required: true}}
                    name={'password'}
                    type={passwordVisible ? 'text' : 'password'}
                    blockStyle={{marginTop: '12px'}}
                    imgRightWidth={18}
                  />
                  {errors?.password && <p>Введите пароль</p>}
                  {errors?.root && <p>Неверный логин или пароль</p>}
                  {errors?.non_field_errors && <p>{errors?.non_field_errors}</p>}
                  <Button
                    text={'Вход'}
                    type={'submit'}
                    disabled={false}
                    style={{width: '100%', marginTop: '24px', marginBottom: '16px'}}
                  />
                </div>
              )
            }
          </form>
          {!is2FA
            && <center>
                <span
                  onClick={() => {
                    setModalActive(true)
                  }}
                >
                  Забыли пароль?
                </span>
            </center>
          }
          <ModalDefault
            title={'Забыли пароль?'}
            titleMargin={'8px'}
            active={modalActive}
            setActive={setModalActive}
            text={'Обратитесь к администратору для запроса пароля:'}
            textMargin={'8px'}
          >
          </ModalDefault>
        </div>
      </MainContainerWithWindow>
    </FormProvider>
  )
}
