import {FC} from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import {RefCallBack} from "react-hook-form";

const PHONE_LEN_TWELVE = [994, 375, 996, 992, 998, 380];
const PHONE_LEN_ELEVEN = [374, 373, 993];

interface IInputPhoneProps {
  onChange: (...event: string[]) => void
  ref: RefCallBack;
  value: string;
  disabled: boolean;
}

const InputPhone: FC<IInputPhoneProps> = ({onChange, ref, value, disabled}) => {
  const getLength = (prefix: string) => {
    if (prefix?.[0] === '7' || PHONE_LEN_ELEVEN.includes(+prefix)) return 11;
    if (PHONE_LEN_TWELVE.includes(+prefix)) return 12;
    return 12;
  }

  const handleChange = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    const length = getLength(value.slice(0, 3));
    onChange('+' + value.slice(0, length));
  };

  return (
    <input
      type={'text'}
      onChange={handleChange}
      ref={ref}
      value={value}
      className={classNames(styles.input, {[styles.inputDisabled]: disabled})}
      placeholder={'Введите номер телефона'}
      disabled={disabled}
    />
  )
}

export default InputPhone;