import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import {
  IGetWithdrawalRequest,
  IWithdrawalsResponse,
  TWithdrawalUpdateStatusBody
} from '../types/WithdrawalsTypes'

export const WithdrawalsApi = createApi({
  reducerPath: 'WithdrawalsApi',
  tagTypes: ['Withdrawals'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getWithdrawals: build.query<IWithdrawalsResponse, IGetWithdrawalRequest>({
      query: (params) => (
        {
          params: {
            [(params?.status || '').length > 3 ? 'status__in' : 'status' ]: params?.status || '',
            page_size: params?.page_size || 0,
            page: params?.page || 1,
            id: params?.id || undefined
          },
          url: '/withdrawals/',
          method: 'GET',
        }
      ),
      providesTags: ['Withdrawals'],
    }),
    updateWithdrawalStatus: build.mutation({
      query: (options: { body: TWithdrawalUpdateStatusBody; id: string }) => {
        const { body, id } = options
        return {
          url: `/users/change_withdrawal_status/${id}/`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Withdrawals'],
    }),
    getWithdrawalTransfer: build.mutation({
      query: () => ({
        url: `/users/transfer_request`,
        method: 'GET',
      }),
      invalidatesTags: ['Withdrawals'],
    }),
  }),
})

export const { useGetWithdrawalsQuery, useUpdateWithdrawalStatusMutation, useGetWithdrawalTransferMutation } = WithdrawalsApi
