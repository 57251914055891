import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import type IBaseRequest from '../types/Base/BaseRequestInterface'
import { type TResponseGetRequest } from '../types/PaymentsTypes'
import {TCreateCardRequest} from "../types/CardsTypes";
import {IFormParams} from "../../pages/Trader/Payments/components/Header/Header";

export const PaymentsApi = createApi({
  reducerPath: 'PaymentsApi',
  tagTypes: ['Payments'],
  baseQuery: fetchBaseQuery({
    baseUrl: backendUrl + '/',
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getPayments: build.query<TResponseGetRequest, IBaseRequest>({
      query: (params) => ({
        url: 'trader_payments/',
        params: { page: params?.page || 1, page_size: params?.page_size || 0 },
        method: 'GET',
      }),
    }),
    createCashOut: build.mutation<void, IFormParams>({
      query: (body) => {
        return ({
        url: 'cashouts/create_cashout/',
        method: 'POST',
        body,
      })},
    })
  }),
})

export const { useGetPaymentsQuery, useCreateCashOutMutation } = PaymentsApi
