import { type OverflowModel } from '../../types'
import { type TPaymentsResponse } from '../../../redux/types/PaymentsTypes'

const mapPayments = (payments: TPaymentsResponse[] | undefined): OverflowModel[] | undefined => {
  return payments?.map((item: any) => {
    return {
      id: item.id,
      wallet_to: item.wallet_to,
      hash: item.transaction_hash,
      type: item.type,
      status: item.status,
      sum: item.amount,
      date: item.create_dt,
    }
  })
}

export default mapPayments
