import React from 'react'
import { Header } from '../components/Header/Header'
import { MainContainer } from '../../../../components/MainContainer/MainContainer'
import { Content } from '../components/Content/Content'
import {useGetSelfQuery} from "redux/services/TraderApi";

export const Payments = () => {
  const { data: trader } = useGetSelfQuery(undefined);
  return (
    <MainContainer needLogo={false}>
      <div className={'overflow'} style={{ height: '100%', position: 'relative' }}>
        <Header balance={trader?.balance}/>
        <Content />
      </div>
    </MainContainer>
  )
}
