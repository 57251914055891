import {useGetSelfQuery} from 'redux/services/TraderApi'
import {Loading} from 'components/Loading/Loading'
import {Button} from "components/Button/Button";
import {ModalDefault} from "../../../../../components/ModalDefault/ModalDefault";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useForm, FormProvider, set} from "react-hook-form";
import {useCreateCashOutMutation} from "redux/services/PaymentsApi";
import {ControlledInput} from "../../../../../components/ControlledInput/ControlledInput";
import {transformFunction} from "../../../Cards/components/AddCard/utils";
import styles from './styles.module.scss'

export interface IFormParams {
  wallet_to: string
  amount: string
}

interface IHeaderParams {
  balance?: number;
}


export const Header: FC<IHeaderParams> = ({ balance }) => {
  const formMethods = useForm<IFormParams>({
    mode: 'onSubmit',
    shouldUnregister: false,
  })
  const {handleSubmit, reset, watch } = formMethods
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState<string>('');
  const {data: trader, isLoading} = useGetSelfQuery(undefined)
  const address = trader?.blockchain_address
  const [createCashOut] = useCreateCashOutMutation()

  const onSubmit = async (data: IFormParams) => {
    createCashOut({
      wallet_to: data.wallet_to,
      amount: data.amount,
    }).then((response: any) => {
      const error = response?.error?.data?.amount?.[0]
      if (error) {
        setError(error);
        return;
      }
      reset();
      setOpenModal(false);
    })
  }

  const walletTo = watch('wallet_to');
  const amount = watch('amount');

  const disabled = useMemo(() => !walletTo || !amount || !!error, [walletTo, amount, error]);

  useEffect(() => {
    if (+(amount || -Infinity) > (balance || 0)) {
      setError(`Недостаточно средств. Доступный остаток ${balance} USDT.`)
    } else {
      setError('')
    }
  }, [amount, balance]);

  useEffect(() => {
    if (openModal) {
      setError('');
      reset();
    }
  }, [openModal])

  if (isLoading) {
    return <Loading/>
  }

  return (
    <div>
      <h3 className={styles.title}>Пополнение счета</h3>
      <div className={styles.block}>
        Адрес: <p>{address}</p>
      </div>
      <div className={styles.button} >
        <Button text="Вывести средства" action={() => setOpenModal(true)} />
      </div>
      <ModalDefault
        title=""
        active={openModal}
        setActive={setOpenModal}
        key="modalCheck"
      >
        <FormProvider {...formMethods}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <p className={styles.form__title}>
              Необходимо указать номер кошелька в сети TRC-20. Использование другой сети может привести к утере средств.
            </p>
            <div>
              <div className={styles.input__title}>
                <span style={{ color: '#FE5B37' }}>*</span> Адрес кошелька
              </div>
              <ControlledInput
                required
                placeholder={'Введите адрес кошелька'}
                name="wallet_to"
              />
            </div>
            <div className={styles.mt_3}>
              <div className={styles.input__title}>
                <span style={{ color: '#FE5B37' }}>*</span> Введите сумму
              </div>
              <ControlledInput
                required
                rules={{required: true}}
                placeholder={'Введите сумму'}
                name="amount"
                transform={transformFunction}
              />
            </div>
            {error && <p className={styles.error}>{ error }</p>}
            <div className={styles.buttons}>
              <Button text="Отмена" action={() => {setOpenModal(false)}}/>
              <Button text="Ок" type="submit" disabled={disabled} />
            </div>
          </form>
        </FormProvider>
      </ModalDefault>
    </div>
  )
}
