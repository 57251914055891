import {Button} from 'components/Button/Button'
import {FC, useState} from "react";
import styles from './styles.module.css'
import {ControlledInput} from "components/ControlledInput/ControlledInput";

interface IAuth2FA {
  errors: any | null;
  qrData: any | null;
}

export const Auth2FA: FC<IAuth2FA> = ({errors, qrData}) => {
  return (
    <div>
      {qrData &&
        <div className={styles.qr}>
          <div className={styles.qr__text}>Отсканируйте QR код ниже с помощью приложения Google Authenticator: <b>{qrData.secret}</b></div>
          <img src={qrData.qr_code_url} alt="" className={styles.qr__image}/>
        </div>
      }
      <div>Код двухфакторной аутентификаций</div>
      <ControlledInput
        title={''}
        placeholder={'Код двухфакторной аутентификаций'}
        rules={{required: true}}
        name={'otp_code'}
        type={'text'}
        transform={(text: string | undefined) => {
          if (text) {
            return text.replace(/[^0-9]/g, '')?.slice(0, 6)
          }
          return text
        }}
      />
      {errors?.otp_code && <p>{errors?.otp_code}</p>}
      <div className={styles.description}>Введите код из Google Authenticator приложения на вашем мобильном
        устройстве.
      </div>
      <Button
        text={'Проверить код'}
        type={'submit'}
        disabled={false}
        style={{width: '100%', marginTop: '24px', marginBottom: '16px'}}
      />
    </div>
  )
}
