import { Loading } from 'components/Loading/Loading'
import { useGetSelfQuery } from 'redux/services/TraderApi'
import formatNumber from 'utils/functions/formatNumber'
import styles from './styles.module.scss'

export const MoneyStateWithdrawals = () => {
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 })
  if (isLoading) {
    return <Loading />
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <h3 className={styles.title}>Баланс</h3>
        <h4 className={styles.item}>
          USDT<span className={styles.value}>{formatNumber(Number(trader?.balance_outcome))}</span>
        </h4>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>{formatNumber(Number(trader?.my_balance_outcome_in_fiat))}</span>
        </h4>
        <h3 className={styles.title}>Запрошено</h3>
        <h4 className={styles.item}>
          USDT<span className={styles.value}>{formatNumber(Number(trader?.frozen_balance_outcome || 0))}</span>
        </h4>
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Выплачено</h3>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>{formatNumber(Number(trader?.total_outcome))}</span>
        </h4>
      </div>
    </div>
  )
}
