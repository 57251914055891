import styles from './withdrawal.module.css'
import {useRecoilState} from 'recoil'
import {Content} from '../components/Content/Content'
import {defaultActiveWithdrawalsFilter} from 'utils/states/activeFilter.state'
import {MainContainer} from 'components/MainContainer/MainContainer'
import {Rate} from '../../components/Rate/Rate'
import {TimeArea} from '../../components/Timearea/TimeArea'
import {Filter} from '../../components/Filter/Filter'
import {withdrawalsFilters} from 'utils/constants/filters'
import {MoneyStateWithdrawals} from "../components/Content/components/MoneyStateWithdrawals";
import {Button} from "components/Button/Button";
import {useGetWithdrawalTransferMutation} from "redux/services/WithdrawalsApi";
import {useGetSelfQuery} from "redux/services/TraderApi";
import {useEffect, useState} from "react";
import Tooltip from "components/Tooltip";

const Withdrawal = () => {
  const [disabled, setDisabled] = useState(false);
  const [{activeWithdrawalsFilter}, changeFilter] = useRecoilState(defaultActiveWithdrawalsFilter)
  const handleSetFilter = (filter: string) => changeFilter({activeWithdrawalsFilter: filter})

  const [query] = useGetWithdrawalTransferMutation();
  const { data: trader } = useGetSelfQuery(undefined);
  const getTransfer = async () => {
    setDisabled(true)
    await query({}).catch(() => setDisabled(false));
  };

  useEffect(() => {
    if (trader) {
      setDisabled(false);
    }
  }, [trader]);
  return (
    <MainContainer>
      <div style={{height: '100%', position: 'relative'}}>
        <Rate/>
        <MoneyStateWithdrawals/>
        {trader?.button_active ?
          <Tooltip text={String(trader?.button_active)}>
            <Button
              action={getTransfer}
              type="button"
              text="Перевод средств"
              style={{marginBottom: '16px'}}
              disabled
            />
          </Tooltip>
          : <Button
            action={getTransfer}
            type="button"
            text="Перевод средств"
            style={{marginBottom: '16px'}}
            disabled={disabled || trader?.button_active === ''}
          />
        }
        <h3 className={styles.title}>Вывод</h3>
        <TimeArea/>
        <Filter activeFilter={activeWithdrawalsFilter} changeFilter={handleSetFilter} filters={withdrawalsFilters}/>
        <Content/>
      </div>
    </MainContainer>
  )
}

export default Withdrawal