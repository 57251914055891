import {FC, ReactNode} from "react";
import styles from './styles.module.scss'

interface IPropsTooltip {
  children: ReactNode;
  text: string;
}

const Tooltip: FC<IPropsTooltip> = ({ children, text }) => {
  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltip__children}>
        {children}
      </div>
      <div className={styles.tooltip__text}>
        { text }
      </div>
    </div>
  )
}

export default Tooltip;