import { Loading } from 'components/Loading/Loading'
import { useGetSelfQuery } from 'redux/services/TraderApi'
import formatNumber from 'utils/functions/formatNumber'
import styles from './styles.module.scss'

export const MoneyStateOrders = () => {
  const { data: trader, isLoading } = useGetSelfQuery(undefined, { pollingInterval: 5000 })
  if (isLoading) {
    return <Loading />
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <h3 className={styles.title}>Баланс</h3>
        <h4 className={styles.item}>
          USDT<span className={styles.value}>{formatNumber(Number(trader?.balance))}</span>
        </h4>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>{formatNumber(Number(trader?.my_balance_in_fiat))}</span>
        </h4>
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Принято средств</h3>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>{formatNumber(Number(trader?.total_income))}</span>
        </h4>
      </div>
      <div className={styles.block}>
        <h3 className={styles.title}>Заморожено</h3>
        <h4 className={styles.item}>
          USDT<span className={styles.value}>{formatNumber(Number(trader?.frozen_balance))}</span>
        </h4>
        <h4 className={styles.item}>
          {trader?.currency}
          <span className={styles.value}>
            {formatNumber(Number(trader?.my_frozen_balance_in_fiat))}
          </span>
        </h4>
      </div>
    </div>
  )
}
