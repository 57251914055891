import React, { useState } from 'react'
import styles from './EditCardModal.module.css'
import { type CardsModel } from '../../../../../../../../utils/types'
import { ModalDefault } from '../../../../../../../../components/ModalDefault/ModalDefault'
import { SwitchButton } from '../SwitchButton/SwitchButton'
import { ControlledInput } from '../../../../../../../../components/ControlledInput/ControlledInput'
import DeleteCardModal from '../DeleteCardModal/DeleteCardModal'
import { transformFunction } from '../../../../AddCard/utils';

interface TEditCardModalProps {
  card: CardsModel
  editId: number | null
  setEditId: (value: number | null) => void
}
export const EditCardModal = (props: TEditCardModalProps) => {
  const { card, editId, setEditId } = props
  const [modalActive, setModalActive] = useState<boolean>(false)
  const handleClose = () => {
    setEditId(null)
  }
  return (
    <>
      <ModalDefault title={''} active={editId == card.id} setActive={handleClose}>
        <div className={styles.wrapper}>
          <div className={styles.item}>
            <p className={styles.title}>ID: {card.id}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Активность</p>
            <SwitchButton active={card.active} id={card.id}/>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Описание</p>
            <ControlledInput
              placeholder={''}
              name={'newDescription'}
              type={'text'}
              title={''}
              defaultValue={card.description}
            />
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Номер карты:</p>
            <p className={styles.value}>{card.account_number}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>ФИО:</p>
            <p className={styles.value}>{card.fio}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Банк:</p>
            <p className={styles.value}>{card.bank.name}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Общий лимит:</p>
            <ControlledInput
              defaultValue={card.total_limit}
              placeholder={'Лимит карты'}
              name={'newLimit'}
              type={'text'}
              rules={{required: true, maxLength: 13}}
              transform={(text: string | undefined) => {
                if (text) {
                  return text
                    .replace(/[^0-9]/g, '')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
                    .slice(0, 13)
                }
                return text
              }}
            />
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Заявок одновременно не более:</p>
            <ControlledInput
              defaultValue={String(card.instant_active_orders)}
              placeholder={'0'}
              name={'newInstantOrders'}
              type={'text'}
              rules={{required: true}}
              transform={(text: string | undefined) => {
                if (text) {
                  return text.replace(/[^0-9]/g, '')}
                return text
              }}
            />
          </div>
          <div className={styles.item}>
            <p className={styles.title}>Кол-во успешных пополнений в сутки:</p>
            <ControlledInput
              defaultValue={String(card.successful_deposit_limit)}
              maxValue={100000000}
              placeholder={'0'}
              name={'successful_deposit_limit'}
              type={'text'}
              blockStyle={{marginBottom: '8px'}}
              transform={(text: string | undefined) => {
                if (text) {
                  return text.replace(/[^0-9]/g, '')
                }
                return text
              }}
            />
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.delete}
              onClick={() => {
                setEditId(null)
                setModalActive(true)
              }}
            >
              <img src={'/delete_white_icon.png'} alt={'delete icon'}/> Удалить
            </button>
            <button className={styles.save} type={'submit'}>
              <img src={'/save_white_icon.png'} alt={'save icon'}/> Сохранить
            </button>
          </div>
        </div>
      </ModalDefault>
      <DeleteCardModal id={card.id} setModalActive={setModalActive} modalActive={modalActive}/>
    </>
  )
}
