import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { type TAuthRequest, type TAuthResponse } from '../types/AuthTypes'
import {getTokenFromLocalStorage} from "../../utils/functions/getToken";

export const AuthApi = createApi({
  reducerPath: 'AuthApi',
  tagTypes: ['Auth'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendUrl}`,
  }),
  endpoints: (build) => ({
    auth: build.mutation<TAuthResponse, TAuthRequest>({
      query: (body: TAuthRequest) => ({
        url: `/auth/token/login/?user=${body.username}`,
        method: 'POST',
        body,
      }),
    }),
    unlock: build.mutation<TAuthResponse, null>({
      query: () => ({
        url: `/users/trader/`,
        method: 'PATCH',
        body: {
          trader_id: 5,
          is_active: true
        }
      }),
    }),
  }),
})

export const { useAuthMutation, useUnlockMutation } = AuthApi
