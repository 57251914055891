import {useGetCardsQuery} from '../../../../../../../redux/services/CardsApi'
import mapCards from '../../../../../../../utils/functions/map/mapCards'
import {Loading} from '../../../../../../../components/Loading/Loading'
import {type CardsModel} from '../../../../../../../utils/types'
import {Blocks} from '../../../../../../../components/Blocks/Blocks'
import {EditCardModal} from '../components/EditCardModal/EditCardModal'
import {type TCardsContentProps} from '../../CardsContent'
import React, {useState} from 'react'
import {Pagination} from '../../../../../../../components/Pagination/Pagination'

export const CardsBlocks = (props: TCardsContentProps) => {
  const {currentId, setCurrentId} = props
  const [page, setPage] = useState<number>(1)
  const [pageSize,] = useState<number>(7)
  const {data, isLoading} = useGetCardsQuery(
    {page, page_size: pageSize},
    {
      refetchOnMountOrArgChange: true,
    },
  )
  if (isLoading) {
    return <Loading/>
  }

  return (
    <div>
      <Blocks
        blocks={
          mapCards(data?.results)?.map((card: CardsModel) =>
            <div key={card.id}>
              <div
                className={'block'}
                onClick={() => {
                  setCurrentId(card.id)
                }}
              >
                <div className={'component'}>
                  <p className={'block-component'}>Активность:</p>
                  <p className={'block-value'}>{card.active ? 'Активна' : 'Неактивна'}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Название реквизита:</p>
                  <p className={'block-value'}>{card.tag}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Описание:</p>
                  <p className={'block-value'}>{card.description}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Метод оплаты:</p>
                  <p className={'block-value'}>{card.payment_type}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Номер карты:</p>
                  <p className={'block-value'}>{card.payment_type === 'C2C' ? card.account_number : <>–</>}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Номер счета:</p>
                  <p
                    className={'block-value'}>{card.payment_type === 'SBP' || card.payment_type === 'TRANSFER' ? card.account_number : <>–</>}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>БИК</p>
                  <p
                    className={'block-value'}>{card.bic ?? '-'}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Банк:</p>
                  <p className={'block-value'}>{card.bank.name}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Назначение платежа</p>
                  <p className={'block-value'}>{card.payment_type === 'TRANSFER' ? 'Перевод' : '-'}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Номер телефона:</p>
                  <p className={'block-value'}>{card.phone_number}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Общий лимит:</p>
                  <p className={'block-value'}>{card.total_limit}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Всего пополнений:</p>
                  <p className={'block-value'}>{card.total_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Минимальная сумма платежа:</p>
                  <p className={'block-value'}>{card.min_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Максимальная сумма платежа:</p>
                  <p className={'block-value'}>{card.max_income}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Заявок одновременно не более:</p>
                  <p className={'block-value'}>{card.instant_active_orders}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>Кол-во успешных пополнений в сутки:</p>
                  <p className={'block-value'}>{card.successful_deposit_limit}</p>
                </div>
                <div className={'component'}>
                  <p className={'block-component'}>ФИО:</p>
                  <p className={'block-value'}>{card.fio}</p>
                </div>
                <div className={'block-buttons'}>
                  <div className={'button-component'} style={{color: '#1890FF'}}>
                    <img src={'/edit_icon.png'} alt={'edit_icon'}/>
                    Изменить
                  </div>
                  <div className={'button-component'} style={{color: '#FE5B37'}}>
                    <img src={'/delete_icon.png'} alt={'delete_icon'}/>
                    Удалить
                  </div>
                </div>
              </div>
              <EditCardModal card={card} editId={currentId} setEditId={setCurrentId}/>
            </div>
          ) || []
        }
      />
      <Pagination
        totalPages={Math.ceil((data?.count || 0) / pageSize)}
        setCurrentPage={setPage}
        currentPage={page}
        nextPage={data?.next || page}
        previousPage={data?.previous || page}
      />
    </div>
  )
}
