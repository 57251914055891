import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.css'

export const pushNotify = (text: string, title: string, autotimeout = 5000) => {
  new Notify({
    status: 'error',
    title: title,
    text: text || '',
    effect: 'fade',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout,
    notificationsGap: 20,
    type: 'filled',
    position: 'right top'
  })
}
