import { useGetSelfQuery } from 'redux/services/TraderApi'
import formatNumber from 'utils/functions/formatNumber'
import { Loading } from 'components/Loading/Loading'
import styles from './Header.module.css'

export const Rate = () => {
  const { data: trader, isLoading: TraderLoading } = useGetSelfQuery(undefined, {
    pollingInterval: 5000,
  })
  if (TraderLoading) {
    return <Loading />
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        ABCEX USDT-{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.course))}</div>
      </div>
      <div className={styles.block}>
        Курс СБП:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_sbp))} ({trader?.outcome_percent_sbp}%)</div>
      </div>
      <div className={styles.block}>
        Курс СберБанк:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_sber))} ({trader?.outcome_percent_sber}%)</div>
      </div>
      <div className={styles.block}>
        Курс другие банки:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_other))} ({trader?.outcome_percent_other}%)</div>
      </div>
      <div className={styles.block}>
        Курс TRANSFER:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.outcome_course_transfer ))} ({trader?.outcome_percent_transfer}%)</div>
      </div>
    </div>
  )
}
