import './Applications.css'
import { Warning } from '../components/Warning/Warning'
import { Content } from '../components/Content/Content'
import { useRecoilState } from 'recoil'
import { defaultActiveOrdersFilter } from 'utils/states/activeFilter.state'
import { MainContainer } from 'components/MainContainer/MainContainer'
import { MoneyStateOrders } from '../components/Content/components/MoneyStateOrders'
import { TimeArea } from '../../components/Timearea/TimeArea'
import { Filter } from '../../components/Filter/Filter'
import { ordersFilters } from 'utils/constants/filters'
import { RateOrders } from "../../components/Rate/RateOrders";

export const Orders = () => {
  const [{ activeOrdersFilter }, changeFilter] = useRecoilState(defaultActiveOrdersFilter)
  const handleSetFilter = (filter : string) => changeFilter({ activeOrdersFilter : filter})
  return (
    <MainContainer>
      <div className={'applications'} style={{ height: '100%', position: 'relative' }}>
        <RateOrders />
        <Warning />
        <MoneyStateOrders />
        <h3 className={'applications_title'}>Заявки</h3>
        <TimeArea />
        <Filter activeFilter={activeOrdersFilter} changeFilter={handleSetFilter} filters={ordersFilters} />
        <Content />
      </div>
    </MainContainer>
  )
}
