import './Header.css'
import React, { useState } from 'react'
import { Navbar } from '../Navbar/Navbar'
import { useVisible } from '../../utils/hooks/useVisible'

export const Header = () => {
  const [isNavbarActive, setIsNavbarActive] = useState<boolean>(false)
  const { notVisible } = useVisible()
  if (notVisible) return null
  return (
    <div className={'header'}>
      <div className={'header_wrapper'}>
        <div className={isNavbarActive ? 'background-active' : 'background'}></div>
        <img className={'header-logo'} src={'/paygo.svg'} alt={'header logo'} />
        {isNavbarActive ? (
          <img
            className={'menu-button-cross'}
            src={'/blue_cross.png'}
            alt={'menu button'}
            onClick={() => {
              setIsNavbarActive(false)
            }}
          />
        ) : (
          <img
            className={'menu-button'}
            src={'/menu_button.png'}
            alt={'menu button'}
            onClick={() => {
              setIsNavbarActive(true)
            }}
          />
        )}
        {isNavbarActive ? <Navbar /> : null}
      </div>
    </div>
  )
}
