import React from 'react'
import './Table.css'
import {type TableProps} from '../../utils/interfaces'

export const Table: React.FC<TableProps> = ({thArray, tdArray}) => {
  return (
    <div style={{marginBottom: '30px'}}>
      <table className={'table'}>
        <thead>
        <tr className={'column_header'}>
          {thArray.map((th, index) =>
            <th style={th.style} key={index}>
              {th.name}
            </th>
          )}
        </tr>
        </thead>
        <tbody>
        {tdArray.length === 0 ? (
          <tr>
            <td className={'empty_table'} colSpan={thArray.length}>
              <img src={'/no_data_icon.png'} alt={'no data icon'}/>
            </td>
          </tr>
        ) : (
          tdArray.map((a, index) =>
            <tr key={index}>
              {a.map((b, i) =>
                <td style={b.style} key={i}>
                  {' '}
                  {b.element}
                </td>
              )}
            </tr>
          )
        )}
        </tbody>
      </table>
    </div>
  )
}
