import { memo } from "react";
import { useGetDisputeByIdQuery } from "redux/services/DisputesApi";
import { Loading } from "components/Loading/Loading";
import { mediaUrl } from "utils/constants/url";
import { FileIcon } from "assets/icons";
import styles from "./components/content.module.css";

interface Props {
  orderUid: any
}

const TYPE_IMAGES_LIST = ['png', 'jpg', 'jpeg', 'webp', 'bmp', 'gif'];

export const PureModal = memo(function PureModal(props: Props) {
  const {orderUid} = props
  const {data: dispute, isLoading: isDisputeLoading} = useGetDisputeByIdQuery(orderUid, {
    skip: orderUid === ''
  })
  const getLastSplit = (item: string, symbol: string) => {
    const split = item.split(symbol)
    return split[split.length - 1];
  }
  const filesDataImages = dispute?.files_data &&
    Array.from(dispute.files_data).filter((x) => TYPE_IMAGES_LIST.includes(getLastSplit(x, '.')))

  const filesDataFiles = dispute?.files_data &&
    Array.from(dispute.files_data).filter((x) => !TYPE_IMAGES_LIST.includes(getLastSplit(x, '.')))
  if (isDisputeLoading) return <Loading/>
  return (
    <div style={{maxHeight: '700px', overflow: 'scroll'}}>

        {!!filesDataFiles?.length &&
          <div style={{marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
          <strong>
            Файлы:
          </strong>
            {filesDataFiles?.map((item) =>
              <div key={item} style={{marginTop: '0px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <div className={styles.link}>
                  <FileIcon />
                  <a href={mediaUrl + item} target="_blank">{getLastSplit(item, '/')}</a>
                </div>
              </div>
            )}
          </div>
        }

        {!!filesDataImages?.length &&
          <div style={{marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
          <strong>
            Изображения:
          </strong>

            {filesDataImages?.map((item) =>
              <div key={item} style={{marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '12px'}}>
                <img
                  src={mediaUrl + item}
                  className={styles.checkImage}
                  alt={'check'}
                />
              </div>
            )}
          </div>
        }

      {dispute?.comment &&
        <div style={{display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px'}}>
          <strong>
            Комментарий:
          </strong>
          <div>
            {dispute.comment}
          </div>
        </div>}
    </div>
  )
})