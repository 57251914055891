import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { backendUrl } from '../../utils/constants/url'
import { getTokenFromLocalStorage } from '../../utils/functions/getToken'
import { type TGetSelfResponse } from '../types/TraderTypes'
import {TAuthResponse} from "../types/AuthTypes";

export const TraderApi = createApi({
  reducerPath: 'TraderApi',
  tagTypes: ['Trader'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${backendUrl}`,
    headers: { Authorization: `Token ${getTokenFromLocalStorage()}` },
  }),
  endpoints: (build) => ({
    getSelf: build.query<TGetSelfResponse, void>({
      query: () => ({
        url: '/users/get_self/',
        method: 'GET',
      }),
      providesTags: [{ type: 'Trader', id: 'LIST' }],
    }),
  }),
})

export const { useGetSelfQuery } = TraderApi
