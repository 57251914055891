import styles from './Header.module.css'
import { useGetSelfQuery } from '../../../../../redux/services/TraderApi'
import React from 'react'
import formatNumber from '../../../../../utils/functions/formatNumber'
import { Loading } from '../../../../../components/Loading/Loading'

export const RateOrders = () => {
  const { data: trader, isLoading: TraderLoading } = useGetSelfQuery(undefined, {
    pollingInterval: 5000,
  })
  if (TraderLoading) {
    return <Loading />
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        ABCEX USDT-{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.course))}</div>
      </div>
      <div className={styles.block}>
        Курс С2С:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.income_course_c2c))} ({trader?.c2c_income_percent}%)</div>
      </div>
      <div className={styles.block}>
        Курс СБП:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.income_course_sbp))} ({trader?.sbp_income_percent}%)</div>
      </div>
      <div className={styles.block}>
        Курс TRANSFER:{trader?.currency}:
        <div className={styles.item}>{formatNumber(Number(trader?.income_course_transfer))} ({trader?.transfer_income_percent}%)</div>
      </div>
    </div>
  )
}
