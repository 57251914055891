import {FC, useMemo, useRef, useState} from 'react';
import {getShortString} from "utils/functions/getShortString";
import styles from './style.module.scss';
import classNames from "classnames";

interface IUploadImageProps {
  updateImage: (file: any) => void;
}

const IS_ALLOWED_FORMATS = ['bmp', 'jpg', 'png', 'gif', 'webp', 'jpeg', 'pdf'];
export const ERROR = 'ERROR';

export const UploadImage: FC<IUploadImageProps> = ({updateImage}) => {
  const ref = useRef<any>();
  const openUploader = () => ref?.current?.click?.();
  const [error, setError] = useState('')
  const [value, setValue] = useState<any | null>(null)

  const upload = async (event: any) => {
    const Mb = 1024 * 1024;
    const file = event.target.files?.[0];
    setValue(file);
    if (file.size > 5 * Mb) {
      setError(`${file.name} Слишком большой размер файла. Пожалуйста, используете файлы с размером не превышающим 5 Mb`);
      updateImage(ERROR);
      return;
    }
    const format = file.name.split('.')?.slice(-1)?.[0];
    if (!IS_ALLOWED_FORMATS.includes(format)) {
      setError(`Расширение файлов “${format}” не поддерживается. Разрешенные расширения: ${IS_ALLOWED_FORMATS.join(', ')}`);
      updateImage(ERROR);
      return;
    }
    setError('');
    updateImage(file);
  }

  return (
    <div className={styles.downloader}>
      <div onClick={openUploader} className={styles.files}>
        {value
          ? <p key={value.name}>
              {getShortString(value.name)}
            </p>
          : 'Загрузить чеки'
        }
      </div>
			{error &&
        <div className={styles.error}>
          {error}
        </div>
      }
      <input
        ref={ref}
        type="file"
        id="input"
        accept="image/bmp, image/jpg, image/png, image/gif, image/webp, image.jpeg, application/pdf"
        onChange={upload}
        className={styles.hidden}
      />
    </div>
  );
};
